import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { NavComponent } from '../../components/nav/nav.component';
import { FooterComponent } from '../../components/footer/footer.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-services',
  standalone: true,
  imports: [
    NavComponent,
    FooterComponent,
    RouterModule
  ],
  templateUrl: './services.component.html',
  styleUrl: './services.component.scss'
})
export class ServicesComponent {
  @ViewChild('marvelerSection', { static: false }) marvelerSection: ElementRef;
  @ViewChild('counselorSection', { static: false }) counselorSection: ElementRef;
  
  constructor(private renderer: Renderer2) { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }
  ngAfterViewInit() {
    window.addEventListener('scroll', function () {
      var video = document.querySelector('video');
      var videoPosition = video?.getBoundingClientRect().top || 0;
      var windowHeight = window.innerHeight || 0;
      if (videoPosition < 0 || videoPosition > windowHeight) {
        video?.pause();
      } else {
        video?.play();
      }
    });
  }


  scrollToMarveler() {
    this.scrollToSection(this.marvelerSection.nativeElement);
  }

  scrollToCounselor() {
    this.scrollToSection(this.counselorSection.nativeElement);
  }

  private scrollToSection(section: HTMLElement) {
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
  
}
