<app-nav></app-nav>

<div class="container">
    <main>
        <video controls autoplay oncanplay="this.play()" width="75%">
            <source src="../../assets/video/video about us (3).mp4" type="video/mp4">
        </video>
    </main>

    <div class="services-container">
        <section class="service marveler">
            <div class="service-image-container">
                <img src="assets/images/marveler.png" alt="" class="service-image">
                <div class="image-text image-text-marveler">Marveler</div>
            </div>
            <div class="service-card">

                <div class="service-card-title">
                    <h2 class="service-card-title-marveler">
                        Why Should I Become a Marveler?
                    </h2>
                    <img src="assets/icons/search-primary.svg" alt="" class="search-icon">
                </div>
                <div class="service-card-details">

                    <p>Marv instantly matches you with a trusted local buddy/expert, Known as the Counselor, who crafts
                        a
                        personalized journey enriched with culture, based on your unique
                        preferences, saving you time and enhancing your travel experience.</p>
                </div>
                <div class="action-btn">
                    <button class="primary-btn primary-btn--primary" (click)="scrollToMarveler()">
                        Go to services
                    </button>
                </div>
            </div>

        </section>

        <section class="service counselor" >
            <div class="service-image-container">
                <img src="assets/images/counselor.jpg" alt="" class="service-image">
                <div class="image-text image-text-counselor">Counselor</div>
            </div>
            <div class="service-card">
                <div class="service-card-title">
                    <h2 class="service-card-title-counselor">
                        Why Should I Become a Counselor?
                    </h2>
                    <img src="assets/icons/search-secondary.svg" alt="" class="search-icon">
                </div>
                <div class="service-card-details service-card-details-counselor">
                    <p>As a Counselor at Marv your benefits are enhancing skills, increasing visibility, and networking
                        with
                        like-minded travelers. As a guide, you can earn money through any channel, but as a Counselor,
                        you
                        will
                        boost your potential towards a career of excellence.</p>
                </div>
                <div class="action-btn">
                    <button class="primary-btn primary-btn--secondary" (click)="scrollToCounselor()">
                        Go to services
                    </button>
                </div>

            </div>


        </section>

        <div class="services-card-container"  >
            <div class="services" #marvelerSection>
                <h2 class="primary">Services for Marvelers</h2>

                <div class="d-flex">
                    <div class="services-card services-card-ask">
                        <img src="assets/icons/video-call.jpg" class="services-icon" alt="">
                        <h3>Ask the Counselor</h3>
                        <ul>
                            <li><img src="assets/icons/approved.jpg" alt="">Personalized recommendations</li>
                            <li><img src="assets/icons/approved.jpg" alt="">Hidden spots known only by locals</li>
                            <li><img src="assets/icons/approved.jpg" alt="">Updated info about the tourist attractions
                            </li>
                            <li><img src="assets/icons/approved.jpg" alt="">Restaurants recommendations based on your
                                preferences</li>
                            <li><img src="assets/icons/approved.jpg" alt="">Culture exchange</li>
                            <li><img src="assets/icons/approved.jpg" alt="">Language tips to get by</li>
                            <li><img src="assets/icons/approved.jpg" alt="">Tips & tricks for having the best travel
                                experience</li>
                            <li><img src="assets/icons/approved.jpg" alt="">Personalized itinerary</li>
                            <li><img src="assets/icons/approved.jpg" alt="">How to avoid tourist traps</li>
                            <li><img src="assets/icons/approved.jpg" alt="">Real-time help with any unforeseen
                                situations through our SOS community</li>
                            <li class="disabled"><img src="assets/icons/close.jpg" alt="">Meeting the Counselor in
                                person</li>
                            <li class="disabled"><img src="assets/icons/close.jpg" alt="">Exploring the city with a
                                local</li>
                            <li class="disabled"><img src="assets/icons/close.jpg" alt="">Additional services that each
                                Counselor offers such as car ride, boat ride, language
                                support,
                                wildlife spotting, culinary experiences, photography, adventure sports, artisan visits
                                and
                                so
                                on.</li>
                            <li><img src="assets/icons/wait.jpg" alt="">more coming soon</li>

                        </ul>
                    </div>
                    <div class="services-card services-card-meet">
                        <img src="assets/icons/meet.jpg" alt="" class="services-icon">
                        <h3>Meet the Counselor</h3>
                        <ul>
                            <li><img src="assets/icons/approved.jpg" alt=""> Personalized recommendations</li>
                            <li><img src="assets/icons/approved.jpg" alt=""> Hidden spots known only by locals</li>
                            <li><img src="assets/icons/approved.jpg" alt=""> Updated info about the tourist attractions
                            </li>
                            <li><img src="assets/icons/approved.jpg" alt=""> Restaurants recommendations based on your
                                preferences</li>
                            <li><img src="assets/icons/approved.jpg" alt=""> Culture exchange</li>
                            <li><img src="assets/icons/approved.jpg" alt=""> Language tips to get by</li>
                            <li><img src="assets/icons/approved.jpg" alt=""> Tips & tricks for having the best travel
                                experience</li>
                            <li><img src="assets/icons/approved.jpg" alt=""> Personalized itinerary</li>
                            <li><img src="assets/icons/approved.jpg" alt=""> How to avoid tourist traps</li>
                            <li><img src="assets/icons/approved.jpg" alt=""> Real-time help with any unforeseen
                                situations through our SOS community</li>
                            <li><img src="assets/icons/approved.jpg" alt=""> Meeting the Counselor in person</li>
                            <li><img src="assets/icons/approved.jpg" alt=""> Exploring the city with a local</li>
                            <li><img src="assets/icons/approved.jpg" alt=""> Additional services that each Counselor
                                offers such as car ride, boat ride, language
                                support,
                                wildlife spotting, culinary experiences, photography, adventure sports, artisan visits
                                and
                                so
                                on.</li>
                            <li><img src="assets/icons/wait.jpg" alt="">more coming soon</li>

                        </ul>
                    </div>
                </div>    
                <div class="join-now marv">
                    <div>
                        Pre-Register and Get <span style="font-size: 36px;text-shadow: 0 0 5px white;"><br>30%
                            off<br></span> on your first trip.
                    </div>
                    <div class="button" [routerLink]="['/form-marv']" routerLinkActive="router-link-active" >
                        JOIN NOW
                    </div>
                    <br>
                    <div>
                        August Launch! Mark Your Calendars!
                    </div>
                </div>
            </div>

            <div class="services" #counselorSection>
                <h2 class="secondary">Services for Counselors</h2>
                <div class="d-flex">
                    <div class="services-card services-card-local">
                        <img src="assets/icons/meet-happy.jpg" alt="" class="services-icon">
                        <h2>Become a Local Buddy</h2>
                        <p>You have:</p>
                        <ul>
                            <li><img src="assets/icons/approved-primary.jpg" alt="">Deep knowledge of your city</li>
                            <li><img src="assets/icons/approved-primary.jpg" alt="">Passion for your city</li>
                            <li><img src="assets/icons/approved-primary.jpg" alt="">The ability to share information
                                with others</li>
                            <li class="disabled-secondary"><img src="assets/icons/close.jpg" alt="">Certifications</li>
                            <li class="disabled-secondary"><img src="assets/icons/close.jpg" alt="">Experience as a
                                guide</li>
                        </ul>
                        <p>Marv helps you with:</p>
                        <ul>
                            <li><img src="assets/icons/approved-primary.jpg" alt="">Dedicated programs for enhancing
                                your skills (such as communication, languages,
                                promotion
                                and
                                others)</li>
                            <li><img src="assets/icons/approved-primary.jpg" alt="">Increased visibility through our
                                website and marketing tools</li>
                            <li><img src="assets/icons/approved-primary.jpg" alt="">Customizable dashboard for managing
                                your bookings, tracking your earnings, and following
                                your
                                progress</li>
                            <li><img src="assets/icons/approved-primary.jpg" alt="">A community of Counselors in your
                                city to support each other and help you with
                                unexpected
                                situations
                            </li>
                            <li><img src="assets/icons/approved-primary.jpg" alt="">The opportunity to become a local
                                expert</li>
                            <li><img src="assets/icons/approved-primary.jpg" alt="">Pricing algorithm</li>
                            <li class="disabled-secondary"><img src="assets/icons/close.jpg" alt="">Flexibility to
                                choose your price</li>
                            <li><img src="assets/icons/approved-primary.jpg" alt="">Feedback to improve your service
                            </li>
                            <li class="disabled-secondary"><img src="assets/icons/close.jpg" alt="">A career path with
                                positions such as the manager of the Counselors in your city</li>
                            <li><img src="assets/icons/wait.jpg" alt="">more coming soon</li>
                        </ul>
                    </div>
                    <div class="services-card services-card-expert">
                        <img src="assets/icons/expert.jpg" alt="" class="services-icon">
                        <h2>Become a Local Expert</h2>
                        <p>You have:</p>
                        <ul>
                            <li><img src="assets/icons/approved-primary.jpg" alt="">Deep knowledge of your city</li>
                            <li><img src="assets/icons/approved-primary.jpg" alt="">Passion for your city</li>
                            <li><img src="assets/icons/approved-primary.jpg" alt="">The ability to share information
                                with others</li>
                            <li><img src="assets/icons/approved-primary.jpg" alt="">Certifications</li>
                            <li><img src="assets/icons/approved-primary.jpg" alt="">Experience as a guide</li>
                        </ul>
                        <p>Marv helps you with:</p>
                        <ul>
                            <li><img src="assets/icons/approved-primary.jpg" alt="">Dedicated programs for enhancing
                                your skills (such as communication, languages,
                                promotion
                                and
                                others)</li>
                            <li><img src="assets/icons/approved-primary.jpg" alt="">Increased visibility through our
                                website and marketing tools</li>
                            <li><img src="assets/icons/approved-primary.jpg" alt="">Customizable dashboard for managing
                                your bookings, tracking your earnings, and following
                                your
                                progress</li>
                            <li><img src="assets/icons/approved-primary.jpg" alt="">A community of Counselors in your
                                city to support each other and help you with
                                unexpected
                                situations
                            </li>
                            <li class="disabled-secondary"><img src="assets/icons/close.jpg" alt="">The opportunity to
                                become a local expert</li>
                            <li><img src="assets/icons/approved-primary.jpg" alt="">Pricing algorithm</li>
                            <li><img src="assets/icons/approved-primary.jpg" alt="">Flexibility to choose your price
                            </li>
                            <li><img src="assets/icons/approved-primary.jpg" alt="">Feedback to improve your service
                            </li>
                            <li><img src="assets/icons/approved-primary.jpg" alt="">A career path with positions such as
                                the manager of the Counselors in your city</li>
                            <li><img src="assets/icons/wait.jpg" alt="">more coming soon</li>
                        </ul>
                    </div>
                </div>
                <div class="join-now">
                    <div>
                        Pre-Register now and get featured on our Social Media profiles.
                    </div>
                    <div class="button" [routerLink]="['/form-co']" routerLinkActive="router-link-active" >
                        JOIN NOW
                    </div>
                    <br>
                    <div>
                        August Launch! Mark Your Calendars!
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>
<app-footer></app-footer>